import { buttonHPadding, type ButtonPropsSizeType } from '@/twComponents/common';
import { cn } from '@/utils/cn';
import type { ComponentProps } from 'react';

type ButtonTextProps = {
  size?: ButtonPropsSizeType;
  children: ComponentProps<'div'>['children'];
};
export default function ButtonText({ size, children }: ButtonTextProps) {
  return <span className={cn('self-center whitespace-nowrap font-semibold', buttonHPadding(size))}>{children}</span>;
}
