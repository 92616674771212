'use client';

import { Button, ButtonLink } from '@/twComponents/Button';
import { cn } from '@/utils/cn';
import Link from 'next/link';
import React, { useState, useEffect } from 'react';
import { LogoIcon, LogoText } from '~/app/_components/ToneIcons';
import { Menu01, XMark } from './StrokeIcons';
import FatHeaderHover from '~/app/_components/FatHeaderHover/FatHeaderHover'; // Adjust the path as necessary

export const NavSection = () => {
  const [opened, setOpened] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  // Ensure the menu opens on button click
  const handleMenuToggle = () => setOpened((o) => !o);

  return (
    <>
      <div
        className={cn(
          'sticky flex items-stretch justify-center top-0 h-16 z-1000 backdrop-blur-sm  overflow-hidden',
          'border-neutral-200 border-b border-white/20 bg-white/35',
        )}
      >
        <div className="flex items-center justify-between w-full max-w-[1300px] px-4 md:px-12.5">
          <Link href={'/'} className="flex items-center gap-2.5 [&>svg]:w-auto text-black">
            <LogoIcon />
            <LogoText />
          </Link>

          <div className="hidden items-center gap-7 lg:flex">
            <ButtonLink className="font-bold" href="/pricing" text>
              Pricing
            </ButtonLink>
            <ButtonLink className="font-bold" href="/posts" text>
              Resources
            </ButtonLink>
            <ButtonLink className="font-bold" href="/ria" text>
              RIA
            </ButtonLink>
            <ButtonLink className="font-bold" href="/advisor-hub" text>
              Advisor Hub
              <sup className="bg-indigo-500 text-white text-2xs rounded-full px-2 py-1 ml-1">
                NEW
              </sup>
            </ButtonLink>
            <ButtonLink className="font-bold" href="/glossary" text>
              Sales Glossary
            </ButtonLink>
            <ButtonLink className="font-bold" href="/login" text>
              Log in
            </ButtonLink>
            <ButtonLink className="font-bold" href="/signup">
              Start
            </ButtonLink>
          </div>
          <Button className="lg:hidden" text icon={<Menu01 />} onClick={handleMenuToggle} />
        </div>
      </div>

      <div
        className={cn(
          'fixed z-10000 top-0 right-0 bottom-0 w-full backdrop-blur-lg bg-white/90 transition-all',
          { 'translate-x-0 opacity-100': opened, 'translate-x-full opacity-0': !opened },
        )}
      >
        <div className="flex justify-end h-16 px-4 items-center border-neutral-200 border-b">
          <Button text icon={<XMark />} onClick={handleMenuToggle} />
        </div>
        <div className="flex flex-col gap-1.5 px-4 mt-1.5">
          <ButtonLink size="md" text href="/pricing" onClick={() => setOpened(false)}>
            Pricing
          </ButtonLink>
          <ButtonLink size="md" text href="/posts" onClick={() => setOpened(false)}>
            Knowledge Center
          </ButtonLink>
          <ButtonLink size="md" text href="/glossary" onClick={() => setOpened(false)}>
            Glossary
          </ButtonLink>
          <ButtonLink size="md" text href="/login" onClick={() => setOpened(false)}>
            Log in
          </ButtonLink>
          <ButtonLink size="md" text type="submit" href="/signup" onClick={() => setOpened(false)}>
            Get Started
          </ButtonLink>
        </div>
      </div>
      {isHovering && (
        <div className="absolute top-full left-0 z-10">
          <FatHeaderHover />
        </div>
      )}
    </>
  );
};
